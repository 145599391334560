import React from 'react';
import Modal from './Modal';

const RouteDetailView = ({ route, date, onClose, onPushRoute, onDataChange }) => {
  // Display route details and provide quick actions.
  // In a real scenario, you’d allow reordering jobs, assigning crews/employees, etc.
  // Here is a simplified version.

  const allJobs = Object.values(route.jobs || {}).flat();

  return (
    <Modal onClose={onClose}>
      <h2 className="text-xl font-bold mb-4">{route.name || 'Unnamed Route'} - {date.toDateString()}</h2>
      <div className="space-y-2">
        {allJobs.length > 0 ? (
          allJobs.map(job => (
            <div key={job._id} className="bg-gray-700 p-2 rounded">
              {job.customer?.name} - {job.service}
            </div>
          ))
        ) : (
          <p>No jobs in this route yet.</p>
        )}
      </div>
      <div className="mt-4">
        <h3 className="font-bold mb-2">Quick Actions</h3>
        <div className="flex space-x-4">
          <button onClick={() => onPushRoute(1, 'day')} className="bg-blue-500 px-4 py-2 rounded hover:bg-blue-600">Push Route a Day</button>
          <button onClick={() => onPushRoute(7, 'day')} className="bg-blue-500 px-4 py-2 rounded hover:bg-blue-600">Push Route a Week</button>
        </div>
      </div>
    </Modal>
  );
};

export default RouteDetailView;

