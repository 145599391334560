// src/pages/Calendar.js
import React, { useState, useEffect, useCallback } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { parse, startOfWeek, getDay, format, addDays, differenceInDays } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Header from '../components/Header';
import Modal from '../components/Modal';
import ConfirmationModal from '../components/ConfirmationModal';
import RouteDetailView from '../components/RouteDetailView';
import DayCellWrapper from '../components/DayCellWrapper';
import JobPool from '../components/JobPool';  
import { fetchWithAuth } from '../components/utils';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const locales = { 'en-US': enUS };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const RECURRENCE_TAGS = {
  Weekly: '{W}',
  'Bi-weekly': '{BW}',
  Monthly: '{M}',
  'One-time': '{O}',
};

const RECURRENCE_INTERVALS = {
  'Weekly': 7,
  'Bi-weekly': 14,
  'Monthly': 30,
};

function isRecurringJobDueOnDate(job, date) {
  const jobDate = new Date(date);
  jobDate.setHours(0,0,0,0);

  if (['Paused', 'Canceled'].includes(job.recurringStatus) || job.status === 'Completed') {
    return false;
  }

  if (!job.isRecurring || !job.recurrencePattern) {
    // One-time job
    if (job.status === 'Completed') return false;
    const baseline = job.startDate ? new Date(job.startDate) : null;
    if (!baseline) return true;
    baseline.setHours(0,0,0,0);
    return jobDate >= baseline;
  }

  const baseline = job.lastServiceDate
    ? new Date(job.lastServiceDate)
    : job.startDate
      ? new Date(job.startDate)
      : new Date('2023-01-01');
  
  baseline.setHours(0,0,0,0);
  const interval = RECURRENCE_INTERVALS[job.recurrencePattern] || 7;
  const daysSince = differenceInDays(jobDate, baseline);
  return (daysSince >= 0 && daysSince % interval === 0);
}

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [jobPool, setJobPool] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [multipleRouteChoice, setMultipleRouteChoice] = useState({ isOpen: false, day: null, job: null, routes: [] });
  const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false, message: '', onConfirm: null, onCancel: null });
  const [recurrenceDialog, setRecurrenceDialog] = useState({ isOpen: false, job: null, oldDate: null, newDate: null });

  const fetchData = useCallback(async () => {
    try {
      const [routesResponse, jobsResponse] = await Promise.all([
        fetchWithAuth('/routes'),
        fetchWithAuth('/jobs')
      ]);

      const routes = routesResponse.routes || {};
      const jobs = jobsResponse || [];

      const jobMap = {};
      jobs.forEach(job => {
        jobMap[job._id] = job;
      });

      const newEvents = [];
      const startDate = addDays(new Date(), -30);
      const endDate = addDays(new Date(), 90);

      for (let date = new Date(startDate); date <= endDate; date = addDays(date, 1)) {
        const dayOfWeek = format(date, 'EEEE');
        const dayRoutes = routes[dayOfWeek] || [];
        dayRoutes.forEach((route) => {
          const routeJobs = Array.isArray(route.jobs)
            ? route.jobs.map(j => jobMap[j._id || j]).filter(Boolean)
            : Object.values(route.jobs || {}).flat().map(j => jobMap[j._id || j]).filter(Boolean);

          const filteredJobs = routeJobs.filter(job => isRecurringJobDueOnDate(job, date));

          if (filteredJobs.length > 0) {
            const start = new Date(date);
            start.setHours(7,0,0,0);
            const end = new Date(date);
            end.setHours(17,0,0,0);

            const tag = route.tag && RECURRENCE_TAGS[route.tag] ? ` ${RECURRENCE_TAGS[route.tag]}` : '';
            newEvents.push({
              title: `${route.name || 'Unnamed Route'}${tag}`,
              start,
              end,
              allDay: false,
              resource: {
                route,
                routeJobs: filteredJobs,
                dayOfWeek,
                date: new Date(date)
              }
            });
          }
        });
      }

      setEvents(newEvents);

      // Determine which jobs are scheduled
      const scheduledJobIds = new Set(
        newEvents.flatMap(e => e.resource.routeJobs.map(j => j._id))
      );

      // Unscheduled jobs
      const unscheduledJobs = jobs.filter(j => 
        !scheduledJobIds.has(j._id) && 
        j.status !== 'Completed' && 
        j.recurringStatus !== 'Paused' &&
        j.recurringStatus !== 'Canceled'
      );

      setJobPool(unscheduledJobs);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load calendar data.');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSelectEvent = (event) => {
    setSelectedRoute({ route: event.resource.route, date: event.resource.date });
  };

const handleJobDropOnDay = async (job, date) => {
  try {
    const dayOfWeek = format(date, 'EEEE');
    const dayEvents = events.filter(e => format(e.resource.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));

    if (dayEvents.length === 0) {
      // Create a new route with timestamp to ensure unique name
      const timestamp = new Date().getTime();
      const newRouteResponse = await fetchWithAuth('/routes', 'POST', {
        dayOfWeek,
        name: `Route ${timestamp}`  // This ensures unique route names
      });
      const newRoute = newRouteResponse.route;
      await addJobToRoute(job, newRoute, date);
    } else if (dayEvents.length === 1) {
      const route = dayEvents[0].resource.route;
      await addJobToRoute(job, route, date);
    } else {
      setMultipleRouteChoice({ isOpen: true, day: date, job, routes: dayEvents.map(e => e.resource.route) });
    }
  } catch (error) {
    console.error('Error handling job drop:', error);
    toast.error('Failed to drop job on day.');
  }
};

  const addJobToRoute = async (job, route, date) => {
    try {
      const payload = { jobId: job._id };
      if (!job.lastServiceDate && job.isRecurring) {
        payload.startDate = format(date, 'yyyy-MM-dd');
      }
      await fetchWithAuth(`/routes/${route._id}/jobs`, 'POST', payload);
      toast.success('Job added to route.');
      fetchData();
    } catch (error) {
      console.error('Error adding job to route:', error);
      toast.error('Failed to add job to route.');
    }
  };

  const onConfirmRouteSelection = async (routeId) => {
    const { job, day } = multipleRouteChoice;
    setMultipleRouteChoice({ ...multipleRouteChoice, isOpen: false });
    try {
      const chosenRoute = multipleRouteChoice.routes.find(r => r._id === routeId);
      if (!chosenRoute) {
        toast.error('Invalid route selected.');
        return;
      }
      await addJobToRoute(job, chosenRoute, day);
    } catch (err) {
      toast.error('Failed to add job to route.');
    }
  };

  const handleRecurringJobMove = (job, oldDate, newDate) => {
    setRecurrenceDialog({ isOpen: true, job, oldDate, newDate });
  };

  const moveJobOccurrence = async (job, oldDate, newDate, updateFuture) => {
    try {
      await fetchWithAuth(`/jobs/${job._id}/move`, 'POST', {
        oldDate: format(oldDate, 'yyyy-MM-dd'),
        newDate: format(newDate, 'yyyy-MM-dd'),
        updateFuture
      });
      toast.success('Job occurrence moved successfully.');
      fetchData();
    } catch (error) {
      console.error('Error moving job occurrence:', error);
      toast.error('Failed to move job occurrence.');
    }
  };

  const confirmRecurrenceMove = (updateFuture) => {
    const { job, oldDate, newDate } = recurrenceDialog;
    setRecurrenceDialog({ isOpen: false });
    moveJobOccurrence(job, oldDate, newDate, updateFuture);
  };

  const pushRoute = async (routeId, interval, unit) => {
    try {
      await fetchWithAuth(`/routes/${routeId}/push`, 'POST', { interval, unit });
      toast.success('Route pushed successfully.');
      fetchData();
    } catch (error) {
      console.error('Error pushing route:', error);
      toast.error('Failed to push route.');
    }
  };

  const eventPropGetter = () => {
    return {};
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="min-h-screen bg-gray-900 text-white flex flex-col">
        <Header />
        <ToastContainer position="top-right" autoClose={3000} />

        {/* Centered Container with increased width */}
        <div className="w-full max-w-7xl mx-auto p-4 flex flex-col space-y-8">
          <div className="bg-white text-black rounded-lg p-4 shadow-lg">
            <BigCalendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              defaultDate={new Date()}
              style={{ height: 600 }}
              eventPropGetter={eventPropGetter}
              onSelectEvent={onSelectEvent}
              views={['month', 'week', 'day', 'agenda']}
              components={{
                dateCellWrapper: (props) => <DayCellWrapper {...props} onJobDrop={handleJobDropOnDay} />
              }}
            />
          </div>

          {/* Job Pool Component */}
          <JobPool 
            jobs={jobPool} 
            onJobDrop={handleJobDropOnDay} 
            onRecurringJobMove={handleRecurringJobMove} 
          />
        </div>

        {multipleRouteChoice.isOpen && (
          <Modal onClose={() => setMultipleRouteChoice({ ...multipleRouteChoice, isOpen: false })}>
            <h2 className="text-xl font-bold mb-4">Select a Route</h2>
            <p className="mb-4">Multiple routes found for this day. Choose one:</p>
            <ul className="space-y-2">
              {multipleRouteChoice.routes.map(r => (
                <li key={r._id}>
                  <button
                    onClick={() => onConfirmRouteSelection(r._id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    {r.name || 'Unnamed Route'}
                  </button>
                </li>
              ))}
            </ul>
          </Modal>
        )}

        {confirmationDialog.isOpen && (
          <ConfirmationModal
            message={confirmationDialog.message}
            onConfirm={confirmationDialog.onConfirm}
            onCancel={() => {
              confirmationDialog.onCancel && confirmationDialog.onCancel();
              setConfirmationDialog({ isOpen: false, message: '', onConfirm: null, onCancel: null });
            }}
          />
        )}

        {recurrenceDialog.isOpen && (
          <Modal onClose={() => setRecurrenceDialog({ ...recurrenceDialog, isOpen: false })}>
            <h2 className="text-xl font-bold mb-4">Move Recurring Job</h2>
            <p>This is a recurring job. Would you like to move just this occurrence or all future occurrences?</p>
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => confirmRecurrenceMove(false)}
                className="bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-800"
              >
                This Occurrence Only
              </button>
              <button
                onClick={() => confirmRecurrenceMove(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                All Future Occurrences
              </button>
            </div>
          </Modal>
        )}

        {selectedRoute && (
          <RouteDetailView
            route={selectedRoute.route}
            date={selectedRoute.date}
            onClose={() => setSelectedRoute(null)}
            onPushRoute={(interval, unit) => pushRoute(selectedRoute.route._id, interval, unit)}
            onDataChange={fetchData}
          />
        )}
      </div>
    </DndProvider>
  );
};
export default CalendarPage;
